import { FormTitle } from '../../texts/FormTitle'
import React, { useEffect } from 'react'
import { Button, notification } from 'antd'
import { StepBaseTemplate } from '../../StepBaseTemplate'
import { useStepByStep } from '../../../../../providers/stepbystep/step-service-provider'
import { URL_QUOTER_PAGE } from '../../../../quoter'
import Form from 'antd/lib/form/Form'
import stateControl from '../../../../../bussines/utils/state-control'
import { useNavigate } from 'react-router-dom'
import { useGetUserProfile } from '../../../../../bussines/hooks/user/useGetUserProfile'
import { UserProfileFieldsContainer } from '../../steps/invitation-user-profile/UserProfileFieldsContainer'
import LottieCargando from '../../../../../lotties/LottieCargando'
import { ChangePassword } from '../ChangePassword'

function EditUserProfile () {
  const navigate = useNavigate()
  const { form, usePostStepCurrentState, handleOnSubmit, handleOnFinish } = useStepByStep()
  const { isLoading, data, isFetching, refetch } = useGetUserProfile()

  useEffect(() => {
    if (usePostStepCurrentState === stateControl.STATE_SUCCESS) {
      notification.success({
        message: 'Your information has been updated succesfully'
      })
    }
  }, [usePostStepCurrentState])

  const onFinish = (values) => {
    handleOnFinish(1, values)
    if (usePostStepCurrentState === stateControl.STATE_SUCCESS) {
      refetch();
      notification.success({
        message: 'Your information has been updated succesfully'
      })
    }
  }

  const handleHomePage = () => {
    navigate(URL_QUOTER_PAGE)
  }

  useEffect(() => {
    if (!isLoading && usePostStepCurrentState === stateControl.STATE_SUCCESS) {
      refetch()
    }
  }, [usePostStepCurrentState, isLoading])

  useEffect(() => {
    if (!isLoading && data.profile) {
      form.setFieldsValue(data.profile)
    }
  }, [data, isLoading])

  if (isLoading || isFetching) {
    return <LottieCargando />
  }

  return (
    <StepBaseTemplate
      justify='space-around'
      footer={
        <div style={{ justifyContent: 'space-between', display: 'flex' }}>
          <Button
            onClick={handleHomePage}
            className='byp-link'
          >
            Back
          </Button>
          <Button
            className='byp-btn-blue-200'
            size='large'
            onClick={handleOnSubmit}
            loading={isLoading}
            disabled={isLoading}
          > Save
          </Button>
        </div>
      }
    >
      <div style={{ margin: '18px 5%' }}>
        <FormTitle
          title='Update your profile'
        />
        <Form
          form={form}
          layout='vertical'
          scrollToFirstError
          name='UpdateAgency'
          onFinish={onFinish}
          initialValues={data?.profile}
        >
          <UserProfileFieldsContainer />
          <ChangePassword />
        </Form>
      </div>
    </StepBaseTemplate>
  )
}

export { EditUserProfile }
