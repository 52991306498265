import { userRoles } from '../../bussines/settings/validate-user-access'
import { PageProtected } from '../../common/components/PageProtected'
import { MainTheme } from '../../common/theme/MainTheme'
import { Button, Col, Form, Row, Typography, notification } from 'antd'
import { SelectCRMFormItem } from './components/SelectCRMFormItem.js.js'
import { BitrixFormFields } from './components/BitrixFormFields'
import { SelectSMTPFormItem } from './components/SelectSMTPFormItem.js'
import { BrevoFormFields } from './components/BrevoFormFields.js'
import { OtherFormFields } from './components/OtherFormFields.js'
import { useGetConfigByCRM } from '../../bussines/hooks/crm/useGetConfigByCRM'
import { useGetConfigByMail } from '../../bussines/hooks/mail/useGetConfigByMail'
import { useEffect } from 'react'
import { useSaveCrmConfiguration } from '../../bussines/hooks/crm/useSaveCrmConfiguration'
import { useSaveMailConfiguration } from '../../bussines/hooks/mail/useSaveMailConfiguration'
import stateControl from '../../bussines/utils/state-control'
const { Title } = Typography

const URL_INTEGRATIONS_PAGE = '/integrations'

function IntegrationsPage () {
  const [form] = Form.useForm()
  const [form_smtp] = Form.useForm()
  const crm = Form.useWatch('crm', form)
  const smtp = Form.useWatch('smtp', form_smtp)
  const { data, isLoading, isError, error } = useGetConfigByCRM(crm)
  const { data:dataMail, isLoading: isLoadingMail, isError:isErrorMail, error:errorMail } = useGetConfigByMail()
  const { save, isLoading: isSaving, currentState, error: errorAtSaving } = useSaveCrmConfiguration()
  const { saveMail, isLoading: isSavingMail, currentStateMail, error: errorAtSavingMail } = useSaveMailConfiguration()

  useEffect(() => {
    if (!isLoading && data) {
      const getContact = data.find((config) => config.configuration?.key === 'ENTERPRISE_BITRIX_GET_CONTACT')
      const addQuote = data.find((config) => config.configuration?.key === 'ENTERPRISE_BITRIX_ADD_QUOTE')
      form.setFieldsValue({
        add_quote_webhook: addQuote?.value,
        get_contact_webhook: getContact?.value
      })
    }
    if (!isLoading && isError) {
      console.log('🚀 ~ file: IntegrationsPage.js:20 ~ useEffect ~ error:', error)
    }
  }, [isLoading])

  useEffect(() => {
    if (!isSaving && currentState === stateControl.STATE_SUCCESS) {
      notification.success({
        message: 'CRM data succesfully saved'
      })
    }
    if (!isSaving && currentState === stateControl.STATE_ERROR) {
      notification.error({
        message: errorAtSaving?.response?.data?.message ?? 'Somethind went wrong'
      })
    }
  }, [isSaving])

  useEffect(() => {
    if (!isSavingMail && dataMail?.smtp !== null) {
      form_smtp.setFieldsValue({
        smtp:dataMail?.smtp,
      })
      if(dataMail?.api_key){
        form_smtp.setFieldsValue({
          api_key:dataMail?.api_key,
        })
      }
      if(dataMail?.smtp_host){
        form_smtp.setFieldsValue({
          smtp_host: dataMail.smtp_host,
          smtp_port:dataMail.smtp_port,
          smtp_username:dataMail.smtp_username,
          smtp_password:dataMail.smtp_password,
          encryption: dataMail.encryption,
        })
      }

    }
    if (!isLoadingMail && isErrorMail) {
      console.log('🚀 ~ file: IntegrationsPage.js:20 ~ useEffect ~ error:', errorMail)
    }
  },[isLoadingMail,form_smtp])

  useEffect(() => {
    if (!isSavingMail && currentStateMail === stateControl.STATE_SUCCESS) {
      notification.success({
        message: 'Mail data succesfully saved'
      })
    }
    if (!isSavingMail && currentStateMail === stateControl.STATE_ERROR) {
      notification.error({
        message: errorAtSavingMail?.response?.data?.message ?? 'Somethind went wrong'
      })
    }
  }, [isSavingMail])

  return (
    <PageProtected permissionTypes={[userRoles.TYPE_ANY, userRoles.TYPE_ANY]}>
      <MainTheme activeCarousel >
        <Title level={2} type='warning' className='pr-5 pl-5 pt-5 pb-2'>Integrations</Title>
        <div className='row pr-5 pl-5'>
          <div className='col-md-6 col-12 mt-3 mb-3'>
            <Title level={5}>Connect to a CRM</Title>
            <Form
              form={form}
              onFinish={(values) => save(values)}
              labelWrap
              layout='horizontal'
              labelCol={{
                md: 12,
                lg: 8
              }}
              labelAlign='left'
            >
              <Row>
                <Col xs={24} md={20} lg={20}>
                  <SelectCRMFormItem name='crm' />
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={20} lg={20}>
                  {crm === 'bitrix' && (
                    <BitrixFormFields />
                  )}
                  {crm && (
                    <Button
                      loading={isSaving}
                      htmlType='submit'
                    >
                      Save
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          </div>
          <div className='col-md-6 col-12 mt-3 mb-3'>
            <Title level={5}>Connect to a SMTP service</Title>
            <Form
              form={form_smtp}
              onFinish={(values) => saveMail(values)}
              labelWrap
              layout='horizontal'
              labelCol={{
                md: 12,
                lg: 8
              }}
              labelAlign='left'
            >
              <Row>
                <Col xs={24} md={20} lg={20}>
                  <SelectSMTPFormItem name='smtp' />
                </Col>
              </Row>
              <Row>
                <Col xs={24} md={20} lg={20}>
                  {smtp === 'brevo' && (
                    <BrevoFormFields />
                  )}
                  {smtp === 'other' && (
                    <OtherFormFields />
                  )}
                  {smtp && (
                    <Button
                      loading={isSavingMail}
                      htmlType='submit'
                    >
                      Save
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          </div>
        </div>
        
        
      </MainTheme>
    </PageProtected>
  )
}

export { IntegrationsPage, URL_INTEGRATIONS_PAGE }
