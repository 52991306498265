import { Form, Select } from 'antd'
const { Option } = Select
const SelectSMTPFormItem = ({ name, ...rest }) => {
  return (
    <Form.Item name={name} {...rest}>
      <Select {...rest} allowClear>
        <Option value='brevo'>Brevo</Option>
        <Option value='other'>Other</Option>
      </Select>
    </Form.Item>
  )
}

export { SelectSMTPFormItem }
