import api from '../../services/api'

class MailRepository {
  async getConfigByMail () {
    const response = await api.get(`/mail`)
    return response.data
  }

  async saveConfigByMail (data) {
    console.log('data to upload',data)
    const response = await api.post('/mail', data)
    return response.data
  }
}

export default new MailRepository()
