import MailRepository from '../../repositories/MailRepository'
import { useState } from 'react'
import stateControl from '../../utils/state-control'
import { useMutation } from 'react-query'

function useSaveMailConfiguration () {
  const [currentStateMail, setCurrentState] = useState(stateControl.STATE_INITIAL)
  const mutation = useMutation(MailRepository.saveConfigByMail, {
    onSuccess: d => {
      setCurrentState(stateControl.STATE_SUCCESS)
    },
    onError: (e) => {
      setCurrentState(stateControl.STATE_ERROR)
    }
  })

  /**
   * save
   * @param data
   */
  const saveMail = (data) => {
    mutation.mutate(data)
  }

  return { ...mutation, currentStateMail, saveMail }
}

export { useSaveMailConfiguration }
