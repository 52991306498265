import { useQuery } from 'react-query'
import MailRepository from '../../repositories/MailRepository'

function useGetConfigByMail () {
  return useQuery(['useGetConfigByMail'],
    () => MailRepository.getConfigByMail(), {
      retry: 1,
    })
}

export { useGetConfigByMail }
