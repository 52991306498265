import { Col, Form, Input, Row } from 'antd'


const BrevoFormFields = () => {
  return (
    <Row gutter={12}>
      <Col xs={24}>
        <Form.Item
          name='api_key'
          label='API key'
          rules={[{ required: true, message: 'This field is required' }]}
        >
        <Input placeholder='your brevo api key' />
        </Form.Item>
      </Col>
    </Row>
  )
}

export { BrevoFormFields }
