import { Col, Form, Input, Row, Select  } from 'antd'
const { Option } = Select
// Webhook entrante: ENTERPRISE_BITRIX_GET_CONTACT
// Webhook entrante: ENTERPRISE_BITRIX_STUDENT_NULL_ID
// Webhook entrante: ENTERPRISE_BITRIX_ADD_QUOTE
const OtherFormFields = () => {
  return (
    <Row gutter={12}>
      <Col xs={24}>
        <Form.Item
          name='smtp_host'
          label='SMTP Server'
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Input placeholder='...server.com' />
        </Form.Item>
      </Col>
      <Col xs={24}>
        <Form.Item
          name='smtp_port'
          label='SMTP Port'
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Input placeholder='587' />
        </Form.Item>
      </Col>
      <Col xs={24}>
        <Form.Item
          name='smtp_username'
          label='User'
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Input placeholder='user' />
        </Form.Item>
      </Col>
      <Col xs={24}>
        <Form.Item
          name='smtp_password'
          label='Password'
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <Input.Password size='large' placeholder='Password'  autoComplete="new-password"/>
        </Form.Item>
      </Col>
      <Col xs={24}>
          <Form.Item name="encryption" label='Encryption'>
          <Select allowClear>
            <Option value='tls'>TLS</Option>
          </Select>
        </Form.Item>
      </Col>
    </Row>
  )
}

export { OtherFormFields }
